
	import request from "@/request";
	import FloorSelector from "@/components/floorSelector/floorSelector.vue";
	import { Component, Vue, Watch } from "vue-property-decorator";
	import { Route } from "vue-router";
	import less from "less";
	@Component({
		components: {
			FloorSelector
		}
	})
		export default class RepairRoot extends Vue {
		floors:{ name:string, id:string }[] = [];
		private MainLink:HTMLLinkElement = document.createElement("link");

		renderCss () {
			less.render("@lightTitleBackground: rgba(0, green(@baseColor)/2, blue(@baseColor)/2, .6);.legends {background-color: @lightTitleBackground;}", {
				javascriptEnabled: true,
				modifyVars: {
					"@baseColor": this.$store.state.baseColor
				}
			}).then((data) => {
				this.MainLink && this.MainLink.remove();
				const href:string = URL.createObjectURL(new Blob([ data.css ]));
				const link = document.createElement("link");
				link.href = href;
				link.rel = "stylesheet";
				document.head.appendChild(link);
				this.MainLink = link;
			});
		}

		mounted () {
			this.renderCss();
			request({
				url: "/space/floor/",
				params: {
					facility: 1
				}
			}).then(({ data }) => {
				this.floors = (data as { name:string, id:string }[]).reverse().map((t) => ({
					name: t.name,
					id: t.id
				}));
			});
		}

		@Watch("$store.state.baseColor")
		onColorChange () {
			this.renderCss();
		}

		beforeRouteLeave (from:Route, to:Route, next:Function):void {
			this.MainLink.remove();
			next();
		}
	}

